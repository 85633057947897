html, body, .App {
  text-align: center;
  overscroll-behavior: none;
}

.dropdown-menu {
  right: 0;
}

.navbar-dropdown-user-control{
  left:auto;
}

.login-form {
  margin-top: 2em;
  justify-content: center;
  align-items: center;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
}

.swiper-close {
  position: absolute;
  top: 4rem;
  right: 4rem;
  color:black;
}

.swiper-close:hover {
  position: absolute;
  top: 4rem;
  right: 4rem;
  color: blue;
}

.login-logo {
  font-size: 1.5em;
  margin-bottom: 2em;
}

.upload-thumbnail {
  max-width: 5em;
}

.list-divider {
  margin-left: .75em;
  margin-right: .75em;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  max-width: 100%;
}

.upload-list {
  padding: 0rem;
  margin-bottom: 5rem;
}

.user-list {
  padding: 0rem;
  margin-bottom: 5rem;
  font-size: .75em;
}

.job-number-input {
  padding-top: .75em;
  padding-bottom: .5em;
}

.small-text {
  font-size: .85em;
}

.nav-footer {
  flex: 100%;
  justify-content: space-between;
  padding-bottom: 1rem;
}

//  .full-height {
//   height: 100vh;
//   background-color: white;
// }

.load-photos-button {
  margin: 1em;
}

.photo-flexbox {
  display: inline-flex;
  //flex-direction: row;
  flex-wrap: wrap;
  //align-items: center;
  //justify-content:flex-start;
  margin-bottom: 5rem;
  gap: 1%;
}

.photo-flexbox-item {
  position: relative;
  max-width: 300px;
  width: 32.6%;
  min-width: 80px;
}

.photo-flexbox-item-image {
  position: absolute;
  max-width: 100%;
  z-index: 2;
}

.upload-control {
  cursor:pointer;
}

.photo-flexbox-item-image:hover {
  cursor:pointer;
}

.photo-flexbox-item-canvas {
  width: 100%;
  background-color: lightgray;
}

.photo-flexbox-item-canvas-end {
  width: 100%;
  background-color: white;
}

.grid-header {
  width: 100%;
  margin-bottom: .75rem;
  text-align: left;
  font-weight: 500;
  font-size: .85em;
}

.hr-header {
  margin-bottom: 0px;
}

// .swiper-flexbox {
//   display: flex;
//   align-items: center;
// }

// .swiper-wrapper-fullscreen{
//   height: 100%;
// }

// .swiper-fullscreen {
//   height: 100vh;
// }

.swiper-image {
  width: 100%;
  z-index: 11;
  //align-self: center;
}

//.swiper {
//  width: 100%;
//  height: 100%;
//}

//.swiper-slide {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //width: 100%;
  //height: 100vh;
  //object-fit: cover;
//}

.photo-view {
  touch-action: pinch-zoom;
  touch-action: double-tap-zoom;
}

.fullscreen-img-container {
  width: 100%;
  height: 100vh;
  position:absolute;
  top: 0px;
  max-width: 100%;
  z-index: 10;
  background-color: black;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
